import * as React from 'react';
import '../../Single.css';
import {useEffect, useState} from "react";
import {getGlobal} from "../../components/Globals";
import Grid from "@mui/joy/Grid";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import {useNavigate, useParams} from "react-router-dom";
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export const Single = () => {

    let [endpoint, setEndpoint] = useState(getGlobal("endpoint"));
    let {id} = useParams();

    const [posts, setPosts] = useState([]);
    const [meta, setMeta] = useState([]);
    const [page, setPage] = React.useState(1);
    const [refresh, setRefresh] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    let [results, setResults] = useState([0]);

    let [title, setTitle] = useState();
    let [description, setDescription] = useState();
    let [created, setCreated] = useState();
    let [video, setVideo] = useState();
    let [folder, setFolder] = useState();

    let [duration, setDuration] = useState();

    useEffect(() => {
        fetch(`${endpoint}public/${id}`, {
            method: 'GET',
        }).then((response) => response.json())
            .then((data) => {

                setTitle(data.title);
                setVideo(data.video);
                setFolder(data.folder);
                setDescription(data.description)

                let date = data.created.split(" ");

                date = date[0].split("-")

                setCreated(date[2]+'.'+date[1]+'.'+date[0]);


            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [0]);


    const setVideoLoaded = () => {
        let vid = document.getElementById("video");

        const time = new Date(vid.duration * 1000).toLocaleTimeString([], {
            minute: "numeric",
            second: "2-digit",
        })

        setDuration(time)
    }

    function handleVideoStart() {
        document.getElementById("startButton").classList.add("hide");
    }

   function handleVideoStop() {
       document.getElementById("startButton").classList.remove("hide");
    }

    return (

        <Grid container spacing={2} className="single">
            <Grid item xs={12} md={12} sx={{position: "relative", textAlign: "center", marginTop: "30px"}}>
                <img src="../logo.png"/>
            </Grid>
            <Grid item xs={12} md={12} sx={{margin: "0 5%", position: "relative", textAlign: "left", marginBottom: "40px"}}>
                <h1>{title}</h1>
            </Grid>
            <Grid xs={6} sx={{padding: "0 8%"}}><p className="infobar left">Durata: {duration}</p></Grid>
            <Grid xs={6} sx={{padding: "0 8%", textAlign: "right"}}><p className="infobar right"><CalendarTodayIcon/> {created}</p></Grid>
            <Grid item xs={12} md={12} sx={{position: "relative", textAlign: "center"}}>

                <video onPlay={handleVideoStart} onPause={handleVideoStop} id="video" src={`${endpoint}uploads/${folder}/${video}`} controls onLoadedData={() => {
                    setVideoLoaded();
                }}/>
                <PlayCircleFilledOutlinedIcon sx={{fontSize: 140}} id="startButton" className='custom-button'/>
            </Grid>

            <Grid item xs={12} md={12} sx={{position: "relative", margin: "0 5%", marginBottom: "200px"}}>
                <p>{description}</p>
            </Grid>
            <Divider variant="middle"/>
            <Grid item xs={12} md={12} sx={{position: "relative", textAlign: "center"}}>
                <a href="/" className='back'><ArrowCircleLeftIcon sx={{fontSize: 60}}/> Torna indietro</a>
            </Grid>

        </Grid>
    );
}

export default Single;