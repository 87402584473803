import React, {useState, useEffect} from 'react';
import {getGlobal} from '../../components/Globals';

import Grid from "@mui/joy/Grid";
import { Player } from 'video-react';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';

const List = () => {
    let [endpoint, setEndpoint] = useState(getGlobal("endpoint"))
    const [posts, setPosts] = useState([]);
    const [meta, setMeta] = useState([]);
    const [page, setPage] = React.useState(1);
    const [refresh, setRefresh] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    let [results, setResults] = useState([0]);

    let [route, setRoute] =  useState('public?page=');

    useEffect(() => {

        fetch(endpoint + route + page, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                setPosts(data.items);
                setMeta(data._meta);
                setRowsPerPage(meta.perPage)
                setResults(data._meta.totalCount);
                setRefresh(false);
            })
            .catch((err) => {
                setRefresh(false);
            });
    }, [page, refresh]);


    return (

        <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{position: "relative", textAlign: "center", marginBottom: "40px", marginTop: "30px"}}>
                <img src="./logo.png" />
            </Grid>
            {posts.map((row) => (
                <a href={`/single/${row.id}`}>
                <Grid item xs={12} md={12}  sx={{ position: "relative", textAlign: "center", marginBottom: "40px" }} >
                    <video src={`${endpoint}uploads/${row.folder}/${row.video}`} controls />
                    <PlayCircleFilledOutlinedIcon sx={{ fontSize: 140 }} className='custom-button' />
                </Grid>
                </a>
                ))}

            </Grid>
    );
}

export default List;