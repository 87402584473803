import * as React from 'react';
import {lazy, Suspense} from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import List from '../pages/video/list';
import Single from '../pages/video/single';

export const Main = () => {

    return (
        <Suspense fallback={<div> </div>}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<List/>}/>
                    <Route path="/single/:id" element={<Single/>}/>
                </Routes>
            </BrowserRouter>
        </Suspense>
    )

}

export default Main;