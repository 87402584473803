import './App.css';
import React, {Component} from 'react';
import Main from './components/Main';
import {CssVarsProvider, useColorScheme} from '@mui/joy/styles';
import teamTheme from './theme';

import Page from "./components/Page";

const Loading = () => (
    <div className="loading" id="loading">
        <div className="anim">
            <div></div>
            <div></div>
        </div>
    </div>
);

class App extends Component {
    state = {
        loading: true,
    };

    componentDidMount() {
        this.isLoading = setTimeout(() => {
            this.setState({loading: false});
            document.getElementById("loading").classList.add("hiding");
        }, 800);
    }

    componentWillUnmount() {
        clearTimeout(this.isLoading);
    }


    render() {
        const {loading} = this.state;
        return (
            <CssVarsProvider disableTransitionOnChange theme={teamTheme}>
                <div className="App">
                    <Loading/>
                    <header className="App-header">

                        <Main/>

                    </header>
                </div>
            </CssVarsProvider>
        );
    }
}

export default App;

